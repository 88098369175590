<template>
  <!-- delete dialog -->
    <div class="el-dialog">
      <div class="el-dialog__container">
        <div class="el-panel">
          <h3 class="el-title">{{ $t('collector.delete.title') }}</h3>
          <p class="el-text"
            v-html="$t('collector.delete.text', { 'collector': collectorToDelete.name })">
          </p>
          <br>
          <!-- accounts list -->
          <p class="el-text" v-for="sub in collectorToDelete.subscriptions"
                            :key="sub.subscriptionId">
            {{ sub.account }}
          </p>
          <br><br>
        </div>
        <!-- close button -->
        <el-toolbar :bottom=true>
          <button class="el-button" @click="deactivateCol">
            {{ $t('button.confirm') }}
          </button>
          <button class="el-button el-button--inverted" @click="$emit('closeDialog')">
            {{ $t('button.cancel') }}
          </button>
        </el-toolbar>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ep-delete-colector-dialog',
  props: {
    collectorToDelete: Object,
  },
  methods: {
    ...mapActions({
      deleteSubsciption: 'collector/deleteSubsciption',
      getUserCollectors: 'collector/getUserCollectors',
      getCollectors: 'collector/getCollectors',
      openSnackbar: 'application/openSnackbar',
    }),
    deactivateCol() {
      for (let i = 0; i < this.collectorToDelete.subscriptions.length; i += 1) {
        this.deleteSubsciption(this.collectorToDelete.subscriptions[i].subscriptionId);
      }
      this.getUserCollectors();
      this.getCollectors();
      this.openSnackbar('application/openSnackbar', {
        message: 'Collecteur désactivé',
        time: 5,
        centered: true,
      }, { root: true });
      this.$emit('closeDialog');
    },
  },
};
</script>
