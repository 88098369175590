<template>
  <!-- eslint-disable max-len -->
  <!-- adding/editing dialog -->
  <div class="el-dialog">
    <div class="el-dialog__container">
      <div class="el-panel">
        <h3 class="el-title">
          <span v-if="editingLayout && (editedSub || add)">{{ $t('collector.add.title.update') }}</span>
          <span v-if="!editingLayout">{{ $t('collector.add.title.add') }}</span>
          <span v-if="!add && !editedSub">{{ $t('collector.add.title.see') }}</span>
          {{ $t('collector.add.title') }}
        </h3>
        <p class="el-text" v-html="$t('collector.add.text', { collector: collectorToAdd.name })" v-if="add"></p>
        <p class="el-text" v-html="$t('collector.see.text', { collector: collectorToAdd.name })" v-else></p>
        <br />
        <!-- accounts list -->
        <div class="el-text" v-for="(sub, index) in collectorToAdd.subscriptions" :key="sub.account">
          <!-- double authentification form -->
          <div v-if="'LOGIN_FAILED' === sub.retrievalStatus && collectorToAdd.isMFA">
            <p class="el-text" v-html="$t('collector.see.mfa-failed.text')"></p>
          </div>

          <div v-if="'ADDITIONAL_AUTH_REQUIRED' === sub.retrievalStatus || ('retrievingDocs' === sub.retrievalStatus && collectorToAdd.isMFA && !mfa)">
            <p class="el-text" v-html="$t('collector.add.mfa.text')"></p>
            <form @submit.prevent="sendCode(sub.subscriptionId, index)">
              <div class="el-input-group el-input-group--default-size">
                {{ sub.account }}
              </div>
              <div class="el-input-group el-input-group--default-size">
                <input
                  v-model="inputDoubleAuth[index]"
                  class="el-input-group__input el-input-group--default-size m-0"
                  type="TEXT"
                  name="Code"
                  :placeholder="$i18n.t('collector.add.authcode')"
                  required="true"
                />
              </div>
              <button type="submit" class="el-button el-button" :title="$t('service.validate')" :aria-label="$t('service.validate')">
                <span class="icon-completed"></span>
              </button>
            </form>
          </div>
          <div v-else>
            <span v-if="sub.retrievalStatus === 'badLogin' || sub.retrievalStatus === 'LOGIN_FAILED'" class="icon-expired" style="color: red"></span>
            <span v-if="'unknown' === sub.retrievalStatus" class="icon-close" style="color: red"></span>
            <span v-if="'retrievingDocs' === sub.retrievalStatus" class="icon-pending"></span>
            {{ sub.account }}
            &nbsp;&nbsp;&nbsp;
            <i>
              <span class="el-link" v-if="editedSub === sub.subscriptionId" @click="editedSub = 0">{{ $t('collector.add.editing') }}</span>
            </i>
            <!-- delete and edit button -->
            <i>
              <button
                class="el-button el-button--small"
                @click="switchToEditMode(sub)"
                v-if="editedSub !== sub.subscriptionId"
                :title="$t('sharing.toolbar.button.edit') + ' ' + $t('dashboard.menu.account') + ' ' + sub.account"
                :aria-label="$t('sharing.toolbar.button.edit') + ' ' + $t('dashboard.menu.account') + ' ' + sub.account"
              >
                <span class="icon-edit"></span>
              </button> </i
            >&nbsp;&nbsp;
            <i>
              <button
                name="suppressCollector"
                class="el-button el-button--small el-button--inverted"
                @click="deactivateCol(sub.subscriptionId)"
                v-if="editedSub !== sub.subscriptionId"
                :title="$t('documents.toolbar.button.delete') + ' ' + $t('dashboard.menu.account') + ' ' + sub.account"
                :aria-label="$t('documents.toolbar.button.delete') + ' ' + $t('dashboard.menu.account') + ' ' + sub.account"
              >
                <span class="icon-supprime"></span>
              </button>
            </i>
            <i>
              <button
                :disabled="timeouts.includes(sub.subscriptionId)"
                class="el-button el-button--small"
                @click="launchCol(sub.subscriptionId)"
                v-if="editedSub !== sub.subscriptionId"
                :title="$t('collector.play') + ' ' + $t('dashboard.menu.account') + ' ' + sub.account"
                :aria-label="$t('collector.play') + ' ' + $t('dashboard.menu.account') + ' ' + sub.account"
              >
                <span class="icon-play"></span>
              </button>
            </i>
            <form @submit.prevent="editSub(index)" v-if="editedSub === sub.subscriptionId">
              <div class="el-input-group el-input-group--default-size" v-for="input in collectorToAdd.inputs" :key="input.name">
                <label class="el-input-group__label" :for="input.name">{{ input.name }}</label>
                <span class="ep-input-container" v-if="input.type === 'PASSWORD'">
                  <input
                    :ref="input.name + '.edit'"
                    class="el-input-group__input pr-6"
                    :type="input.type"
                    :name="input.name"
                    :placeholder="input.info !== 'NONE' ? input.info : input.name"
                    :required="input.required"
                  />
                  <span class="icon-eye ep-input-container__icon" @click="showOrHidePassword($event.target)"></span>
                </span>
                <input
                  v-else
                  :ref="input.name + '.edit'"
                  class="el-input-group__input"
                  :type="input.type"
                  :name="input.name"
                  :placeholder="input.info !== 'NONE' ? input.info : input.name"
                  :required="input.required"
                />
              </div>
              <!-- confirm and cancel button -->
              <button class="el-button" :title="$t('button.confirm')" :aria-label="$t('button.confirm')">
                <span class="icon-save"></span>
              </button>
              <button class="el-button el-button--inverted" @click.prevent="editedSub = 0" :title="$t('button.close')"
                      :aria-label="$t('button.close')">
                <span class="icon-close"></span>
              </button>
            </form>
          </div>
        </div>
        <br />
        <!-- add button -->
        <button
          class="el-button el-button--small"
          @click="switchToAddMode()"
          v-if="!add"
          :title="$t('collector.add.title.add') + ' ' + $t('dashboard.menu.account')"
          :aria-label="$t('collector.add.title.add') + ' ' + $t('dashboard.menu.account')"
        >
          <span class="icon-add"></span>
        </button>
        <!-- dynamic form adapted to collector -->
        <form @submit.prevent="addSub()" v-if="add">
          <div class="el-input-group el-input-group--default-size" v-for="input in collectorToAdd.inputs" :key="input.name">
            <label class="el-input-group__label" :for="input.name">{{ input.name }}</label>
            <span class="ep-input-container" v-if="input.type === 'PASSWORD'">
              <input
                :ref="input.name + '.add'"
                class="el-input-group__input pr-6"
                :type="input.type"
                :name="input.name"
                :placeholder="input.info !== 'NONE' ? input.info : input.name"
                :required="input.required"
              />
              <!-- <span class="icon-eye ep-input-container__icon" @click="showOrHidePassword($event.target)"></span> -->
            </span>
            <input
              v-else
              :ref="input.name + '.add'"
              class="el-input-group__input"
              :type="input.type"
              :name="input.name"
              :placeholder="input.info !== 'NONE' ? input.info : input.name"
              :required="input.required"
            />
          </div>
          <!-- confirm and cancel button -->
          <button class="el-button" :title="$t('button.finish')" :aria-label="$t('button.finish')">
            <span class="icon-completed"></span>
          </button>
          <button
            class="el-button el-button--inverted"
            @click.prevent="add = false"
            v-if="editingLayout"
            :title="$t('profile.cancel')"
            :aria-label="$t('profile.cancel')"
          >
            <span class="icon-close"></span>
          </button>
        </form>
      </div>
      <!-- close button -> text change depending or edit or add -->
      <el-toolbar :bottom="true">
        <button class="el-button el-button--inverted" @click="$emit('closeDialog')">{{ $t('button.close') }}</button>
      </el-toolbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ep-add-colector-dialog',
  props: {
    collectorToAdd: Object,
  },
  data() {
    return {
      editingLayout: false,
      add: false,
      editedSub: 0,
      inputDoubleAuth: [],
      mfa: false,
    };
  },
  computed: {
    ...mapGetters({
      userCollectors: 'collector/userCollectors',
      timeouts: 'collector/timeouts',
    }),
  },
  methods: {
    ...mapActions({
      getUserCollectors: 'collector/getUserCollectors',
      getCollectors: 'collector/getCollectors',
      addSubscription: 'collector/addSubscription',
      updateSubscription: 'collector/updateSubscription',
      sendAuthCode: 'collector/sendAuthCode',
      deleteSubsciption: 'collector/deleteSubsciption',
      launchCollector: 'collector/loadFiles',
      getSubscriptionsForProvider: 'collector/getSubscriptionsForProvider',
      timeoutSubscription: 'collector/timeoutSubscription',
    }),
    deactivateCol(id) {
      this.collectorToAdd.subscriptions.slice(this.collectorToAdd.subscriptions.findIndex(x => x.subscriptionId === id));
      this.deleteSubsciption(id).then(() => {
        this.getUserCollectors();
        // this.getCollectors();
      });
      if (!this.subscriptions) {
        this.$emit('closeDialog');
      }
    },
    launchCol(id) {
      this.timeoutSubscription(id);

      const launchParams = {
        providerId: this.collectorToAdd.id,
        subscriptionIds: [id],
      };
      this.launchCollector(launchParams).then(() => {
        this.getUserCollectors();
      });
    },
    addSub() {
      const inputs = [];
      for (let i = 0; i < this.collectorToAdd.inputs.length; i += 1) {
        inputs.push({
          name: this.collectorToAdd.inputs[i].name.replace(/ /g, '_'),
          value: this.$refs[`${this.collectorToAdd.inputs[i].name}.add`][0].value,
        });
      }
      this.addSubscription({ providerId: this.collectorToAdd.id, inputs }).then(() => {
        const col = this.collectorToAdd;
        if (!col.subscriptions) {
          col.subscriptions = [];
        }
        this.editingLayout = true;
        this.add = false;
        this.getUserCollectors();
      });
    },
    editSub(index) {
      const sub = this.collectorToAdd.subscriptions[index];
      const inputs = [];
      for (let i = 0; i < this.collectorToAdd.inputs.length; i += 1) {
        inputs.push({
          name: this.collectorToAdd.inputs[i].name.replace(/ /g, '_'),
          value: this.$refs[`${this.collectorToAdd.inputs[i].name}.edit`][0].value,
        });
      }
      this.updateSubscription({
        providerId: this.collectorToAdd.id,
        subscriptionId: sub.subscriptionId,
        inputs,
      }).then(() => {
        this.editedSub = 0;
        this.getUserCollectors();
        // this.getCollectors();
      });
    },
    switchToAddMode() {
      this.add = true;
      this.editedSub = 0;
    },
    switchToEditMode(sub) {
      this.add = false;
      this.editedSub = sub.subscriptionId;
      this.$nextTick(() => {
        this.$refs[`${this.collectorToAdd.inputs[0].name}.edit`][0].value = sub.account;
      });
    },
    sendCode(subId, index) {
      this.sendAuthCode({
        subscriptionId: subId,
        code: this.inputDoubleAuth[index],
      }).then(() => {
        this.mfa = true;
        this.inputDoubleAuth[index] = '';
        this.getUserCollectors();
        // this.getCollectors();
      });
    },
    /* showOrHidePassword(target) {
      if (target.parentNode.getElementsByTagName('input')[0].type === 'password') {
        // eslint-disable-next-line
        target.parentNode.getElementsByTagName('input')[0].type = 'text';
        target.classList.add('active');
      } else if (target.parentNode.getElementsByTagName('input')[0].type === 'text') {
        // eslint-disable-next-line
        target.parentNode.getElementsByTagName('input')[0].type = 'password';
        target.classList.remove('active');
      }
    }, */
  },
  mounted() {
    this.editingLayout = this.collectorToAdd.subscriptions;
    if (!this.editingLayout) {
      this.switchToAddMode();
    }
  },
};
</script>
