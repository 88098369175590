<template>
  <div class="ep-popin">
    <div class="ep-popin__popin-form">
      <div @click="$router.push({ name:'logout' })">
        <eui-icon name="close" class="ep-popin__popin-form__close-icon"></eui-icon>
      </div>
      <div class="ep-popin__popin-form__left-part">
      </div>
      <div class="ep-popin__popin-form__right-part">
        <img v-if="!isMIPIH" src='../assets/img/logo.png' class="ep-popin__popin-form__logo"/>
        <img v-else src='../assets/img/logo_MIPIH.png'
             style="height: initial;"
             class="ep-popin__popin-form__logo"/>
        <form>
          <div>
            <p class="ep-popin__popin-form__message">
              {{ $t('activate.popin.evolution.one', { appName: this.appName }) }}
            </p>
          </div>
          <label class="ep-popin__popin-form__label" for="cgu">
            <div>
              <input
                class="el-checkbox ep-popin__popin-form__rgpd-checkbox"
                type="checkbox"
                v-model="conditions.cguAgreed"
              />
            </div>
            <div>
              {{ $t('activate.popin.checkbox.three.first') }}
              <a class="ep-popin__popin-form__link" :href="cgu" target="_blank">
                {{ $t('activate.popin.checkbox.three.span', { appName: this.appName }) }}
              </a>
              {{ $t('activate.popin.checkbox.three.second') }}
            </div>
          </label>
          <br />
          <p class="ep-popin__popin-form__message">
              {{ $t('activate.popin.checkbox.two.first') }}
              <a class="ep-popin__popin-form__link" :href="personalData" target="_blank">
                {{ $t('activate.popin.checkbox.two.span') }}
              </a>
          </p>
          <div class="ep-popin__popin-form__button">
            <EuiButton adaptSize
                       variant="raised"
                       :disabled="!conditions.cguAgreed"
                       :color="conditions.cguAgreed ? 'primary' : ''"
                       @click.prevent="sendAgreement()">
              {{ $t('activate.label.activate.submit.button') }}
            </EuiButton>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import auth from '../utils/auth';

export default {
  name: 'popinRGPD',
  data() {
    return {
      conditions: {
        cguAgreed: false,
      },
      errorMessage: false,
      cgu: 'https://support-edocperso.zendesk.com/hc/fr/articles/19966683438226-Conditions-g%C3%A9n%C3%A9rales-d-utilisation-et-de-vente-de-l-application-eDocPerso',
      personalData: 'https://support-edocperso.zendesk.com/hc/fr/articles/21478345616018-Politique-de-Protection-des-Donn%C3%A9es-Personnelles-Annexe-compl%C3%A9mentaire-eDocPerso',
      isMIPIH: false,
      appName: 'eDocPerso',
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
    }),
  },
  methods: {
    sendAgreement() {
      if (this.conditions.cguAgreed) {
        this.$emit('all-conditions-agreed');
      } else {
        this.errorMessage = true;
      }
    },
  },
  mounted() {
    auth.getConf().then((response) => {
      if (response.CONF.MIPIH) {
        this.isMIPIH = true;
        this.cgu = response.CONF.cguURL;
        this.personalData = response.CONF.personalDataURL;
        this.appName = response.CONF.appName;
      }
    });
  },
};
</script>
